import { useState, useRef, useCallback } from 'react';

// @mui
import { Box, Popover, Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

// components
import PublicProfile from './PublicProfile';
import ColumnBox from 'src/components/Box/ColumnBox';
import CustomButton from 'src/components/Button';

const useStyles = makeStyles({
  outBoxStyle: {
    width: '22px',
    height: '22px',
    cursor: 'pointer',
    '@media (max-width: 1024px)': {
      marginLeft: '15px'
    }
  }
});

const profileButton = {
  name: 'profile',
  avatar: '/static/img/header/profile.svg',
  desc: 'profileButton'
};

const useToogleProfileButton = (initialState) => {
  const [isOpen, setOpen] = useState<boolean>(initialState);
  const handleOpen = useCallback(() => setOpen(true), [isOpen]);
  const handleClose = useCallback(() => setOpen(false), [isOpen]);

  return [isOpen, handleOpen, handleClose] as const;
};

const ProfileButton = () => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);
  const ref = useRef<any>(null);

  const [isOpen, handleOpen, handleClose] = useToogleProfileButton(false);

  return (
    <>
      <Box
        component="img"
        className={classes.outBoxStyle}
        alt={profileButton.name}
        src={profileButton.avatar}
        ref={ref}
        onClick={handleOpen}
      />
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            background: theme.colors.gradients.primary,
            borderRadius: '20px',
            marginTop: '10px'
          }
        }}
      >
        <ColumnBox padding="10px 20px 20px 20px" gap="10px">
          <PublicProfile />
          <CustomButton
            borderRadius="20px"
            fontSize="14px"
            width="100%"
            background={theme.colors.gradients.secondary}
            color={theme.colors.inactiveTitle.main}
          >
            Change to Light theme
          </CustomButton>
          <CustomButton
            borderRadius="20px"
            fontSize="14px"
            width="100%"
            background={theme.colors.gradients.secondary}
            color={theme.colors.inactiveTitle.main}
          >
            Activity Logs
          </CustomButton>
        </ColumnBox>
      </Popover>
    </>
  );
};

export default ProfileButton;
