import { useRef, useState, useCallback, useEffect } from 'react';

// @mui
import { Box, Popover, Typography, Theme, useTheme } from '@mui/material';

// components
import CustomCard from 'src/components/Card';
import ColumnBox from 'src/components/Box/ColumnBox';
import RowBox from 'src/components/Box/RowBox';

// hooks
import { useWeb3React } from '@web3-react/core';
import useMulticallContract from 'src/hooks/useMulticallContract';
import { ContractCallContext } from 'ethereum-multicall';

// utils
import { getContractInfo, numberWithCommas } from 'src/utils';
import { utils } from 'ethers';

const assetButton = {
  name: 'asset',
  avatar: '/static/img/header/asset.svg',
  desc: 'assetIconButton'
};

const keyIcon = {
  name: 'keyIcon',
  path: '/static/img/header/key.svg',
  desc: 'LP Token Key Icon'
};

const useToogleAssetButton = (initialState) => {
  const [isOpen, setOpen] = useState<boolean>(initialState);
  const handleOpen = useCallback(() => setOpen(true), [isOpen]);
  const handleClose = useCallback(() => setOpen(false), [isOpen]);

  return [isOpen, handleOpen, handleClose] as const;
};

interface AssetInfo {
  smtAmount: string;
  smtcAmount: string;
  lpAmount: string;
}
const assetInitialInfo = {
  smtAmount: '',
  smtcAmount: '',
  lpAmount: ''
};

const HeaderNotifications = () => {
  const theme: Theme = useTheme();
  const { account, chainId } = useWeb3React();
  const { getMulticallResults } = useMulticallContract();

  const ref = useRef<any>(null);
  const [isOpen, handleOpen, handleClose] = useToogleAssetButton(false);
  const [userBalances, setUserBalances] = useState<AssetInfo>(assetInitialInfo);

  useEffect(() => {
    async function init() {
      const callData: ContractCallContext[] = [
        {
          reference: 'SmartToken',
          contractAddress: getContractInfo('SmartToken', chainId).address,
          abi: getContractInfo('SmartToken', chainId).abi,
          calls: [
            {
              reference: 'userSMTbal',
              methodName: 'balanceOf',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'SmartTokenCash',
          contractAddress: getContractInfo('SmartTokenCash', chainId).address,
          abi: getContractInfo('SmartTokenCash', chainId).abi,
          calls: [
            {
              reference: 'userSMTCbal',
              methodName: 'balanceOf',
              methodParameters: [account]
            }
          ]
        },
        {
          reference: 'SmartFarm',
          contractAddress: getContractInfo('SmartFarm', chainId).address,
          abi: getContractInfo('SmartFarm', chainId).abi,
          calls: [
            {
              reference: 'userLPbal',
              methodName: 'userInfoOf',
              methodParameters: [account]
            }
          ]
        }
      ];
      const callResults = await getMulticallResults(callData);
      console.log(callResults);
      const { SmartFarm, SmartToken, SmartTokenCash } = callResults.results;
      setUserBalances({
        smtAmount: utils.formatEther(
          SmartToken.callsReturnContext[0].returnValues[0].hex
        ),
        smtcAmount: utils.formatEther(
          SmartTokenCash.callsReturnContext[0].returnValues[0].hex
        ),
        lpAmount: utils.formatEther(
          SmartFarm.callsReturnContext[0].returnValues[1].hex
        )
      });
    }
    if (account) init();

    return () => {
      setUserBalances(assetInitialInfo);
    };
  }, [account, chainId, isOpen]);

  return (
    <>
      <Box
        component="img"
        alt={assetButton.name}
        src={assetButton.avatar}
        sx={{ width: '24px', height: '23px', cursor: 'pointer' }}
        ref={ref}
        onClick={handleOpen}
      />
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          style: {
            background: theme.colors.gradients.primary,
            borderRadius: '20px',
            marginTop: '10px'
          }
        }}
      >
        <ColumnBox padding="20px">
          <Typography variant="h2" height="29px">
            Asset
          </Typography>
          <ColumnBox marginTop="20px">
            <CustomCard
              height="auto"
              width="280px"
              background={theme.colors.gradients.secondary}
              border="none"
            >
              <ColumnBox padding="10px">
                <Typography variant="h4">SMT Balance</Typography>
                <Typography
                  variant="h1"
                  margin="10px 0"
                  color={theme.colors.primary.main}
                >
                  {userBalances.smtAmount}
                </Typography>
                <Typography variant="h4" color={theme.colors.primary.main}>
                  SMT
                </Typography>
              </ColumnBox>
            </CustomCard>

            <CustomCard
              height="auto"
              width="280px"
              background={theme.colors.gradients.secondary}
              border="none"
              marginTop="10px"
            >
              <ColumnBox padding="10px">
                <Typography variant="h4">SMTC Balance</Typography>
                <Typography
                  variant="h1"
                  margin="10px 0"
                  color={theme.colors.primary.main}
                >
                  {userBalances.smtcAmount}
                </Typography>
                <Typography variant="h4" color={theme.colors.primary.main}>
                  SMTC
                </Typography>
              </ColumnBox>
            </CustomCard>

            <CustomCard
              height="auto"
              width="280px"
              background={theme.colors.gradients.secondary}
              border="none"
              marginTop="10px"
            >
              <ColumnBox padding="10px">
                <RowBox justifyContent="center">
                  <Typography variant="h4">LP Token</Typography>
                  <Box
                    component="img"
                    src={keyIcon.path}
                    alt={keyIcon.name}
                    marginLeft="10px"
                  />
                </RowBox>
                <Typography
                  variant="h1"
                  margin="10px 0"
                  color={theme.colors.primary.main}
                >
                  {userBalances.lpAmount}
                </Typography>
                <Typography variant="h4" color={theme.colors.primary.main}>
                  SMT - BUSD LP
                </Typography>
              </ColumnBox>
            </CustomCard>
          </ColumnBox>
        </ColumnBox>
      </Popover>
    </>
  );
};

export default HeaderNotifications;
