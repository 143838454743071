import { getContract } from 'src/utils';
import { useWeb3React } from '@web3-react/core';

const useSmartAchievement = () => {
  const { chainId } = useWeb3React();

  /**
   * Get Nobility Title , User get
   * @param userAccount
   * @returns title
   */
  const getNobilityTypeOf = async (userAccount: string) => {
    try {
      const smtAchieveContract = await getContract(
        'SmartNobilityAchievement',
        chainId
      );
      let nobilityTitleOf = await smtAchieveContract.nobilityTitleOf(
        userAccount
      );
      return nobilityTitleOf;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  return { getNobilityTypeOf };
};

export default useSmartAchievement;
