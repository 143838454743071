import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// @mui
import {
  Box,
  Typography,
  CardContent,
  CardActions,
  Collapse,
  Theme,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// components
import ColumBox from 'src/components/Box/ColumnBox';
import RowBox from 'src/components/Box/RowBox';
import LoadingBar from 'src/components/Loader';

// models
import { ExpandMore, AntSwitch } from 'src/models/layout/StyledData';
import {
  middleButtons,
  shareIcon,
  makePublic,
  privateViewImage,
  publicViewImage
} from 'src/models/layout/SampleData';
import {
  userAvatar,
  profileMark,
  telegramView,
  nonProfileImage
} from 'src/models/ImageUrl';
import { privilege } from 'src/models/layout/SampleData';
import { tooltipImages } from 'src/models/main/achievement/SampleData';

// hooks
import { useWeb3React } from '@web3-react/core';
import useSmartArmy from 'src/hooks/useSmartArmy';
import { useTimeDiscount } from 'src/hooks/useTimeCount';

// utils
import { UserInfoProps } from 'src/utils/interfaces';
import getUserInfo from 'src/utils/getUserInfo';

const useStyles = makeStyles({
  outBoxStyle: (props: Theme) => ({
    padding: '50px 44px 40px 44px !important',
    position: 'relative',
    background: props.colors.gradients.primary,
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '100%',
    textAlign: 'center'
  }),
  closeIconStyle: (props: Theme) => ({
    cursor: 'pointer',
    position: 'absolute',
    top: '15px',
    right: '15px',
    color: props.colors.secondary.main
  }),
  visionaryBoxStyle: {
    height: '25px',
    marginTop: '13px',
    background: '#5A5A5A',
    borderRadius: '20px'
  }
});

const useToogleButton = () => {
  const [isPublic, setPublic] = useState<number>(0);
  const changeSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.checked ? setPublic(1) : setPublic(0);
  };

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = useCallback(
    () => setExpanded(!expanded),
    [expanded]
  );

  return [isPublic, expanded, changeSwitch, handleExpandClick] as const;
};

interface ParentProps {
  onHandleCustoClose: (e: React.MouseEvent<SVGElement>) => void;
  onHandleShareClick: () => void;
}
const initialInfo = {
  userName: '',
  telegram: '',
  userAvatar: '',
  licenseName: '',
  licenseLevel: '0',
  nobilityTitle: ''
};
let entered: boolean = false;

const PublicProfileContent = (props: ParentProps) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const { account, chainId } = useWeb3React();
  const [isPublic, expanded, changeSwitch, handleExpandClick] =
    useToogleButton();
  const { initActivate, isLoading, setIsLoading } = useSmartArmy();
  const { remain } = useTimeDiscount(account, 'm');
  const navigate = useNavigate();

  const onGotoSmartArmyPage = () => {
    navigate('/main/smart');
  };

  const goTelegram = () => {
    window.open('https://t.me/' + userInfo.telegram);
  };

  const [lStatus, setLstatus] = useState<number>(0);
  const [userInfo, setUserInfo] = useState<UserInfoProps>(initialInfo);

  useEffect(() => {
    async function getStatusOfLicense() {
      await getUserInfo(account, chainId).then((res) => {
        if (res) {
          const {
            userAvatar,
            userName,
            telegram,
            licenseName,
            licenseLevel,
            nobilityTitle,
            licenseStatus
          } = res;
          setUserInfo({
            userAvatar,
            userName,
            telegram,
            licenseName,
            licenseLevel,
            nobilityTitle
          });
          setLstatus(licenseStatus);
        }
      });
    }
    if (account) getStatusOfLicense();

    return () => {
      setUserInfo(initialInfo);
      setLstatus(0);
    };
  }, [lStatus, account, chainId]);

  const onHandleActivate = async () => {
    if (!entered && !isLoading) {
      try {
        entered = true;
        setIsLoading(true);
        await initActivate();
        setLstatus(2);
      } catch (err) {
        console.error(err);
        toast.error(err.message);
        setLstatus((prev) => prev);
      } finally {
        entered = false;
        setIsLoading(false);
      }
    }
  };

  const onHandleClose = (e) => {
    if (!isLoading) props.onHandleCustoClose(e);
  };

  return (
    <Box className={classes.outBoxStyle}>
      <CloseIcon
        onClick={(e) => onHandleClose(e)}
        className={classes.closeIconStyle}
      />
      <Box padding="0 20px">
        <Typography variant="h2">Public Profile</Typography>
        {/* TOP SECTION */}
        <Box marginTop="20px" padding="0 30px">
          <Box width="150px" sx={{ position: 'relative', margin: '0 auto' }}>
            {lStatus === 0 || !account ? (
              <Box
                component="img"
                alt={userAvatar.name}
                src={nonProfileImage.avatar}
                sx={{
                  width: '150px',
                  cursor: 'pointer',
                  margin: 'auto'
                }}
              />
            ) : (
              <>
                <Box
                  component="img"
                  alt={userAvatar.name}
                  src={userInfo.userAvatar}
                  sx={{
                    width: '150px',
                    cursor: 'pointer',
                    margin: 'auto',
                    borderRadius: '50%'
                  }}
                />
                {userInfo.nobilityTitle !== '' && (
                  <Box
                    component="img"
                    alt={profileMark.name}
                    src={tooltipImages[userInfo.nobilityTitle].path}
                    sx={{
                      width: '72px',
                      cursor: 'pointer',
                      position: 'absolute',
                      bottom: '0',
                      right: '-20px'
                    }}
                  />
                )}
              </>
            )}
          </Box>
          <Box sx={{ position: 'relative', marginTop: '16px' }}>
            <Typography variant="h2" textAlign="center">
              {lStatus === 0 || !account ? 'User Name' : userInfo.userName}
            </Typography>
            {lStatus !== 0 && (
              <>
                <Box
                  component="img"
                  alt={
                    makePublic[isPublic] === 'Make Private'
                      ? publicViewImage.name
                      : privateViewImage.name
                  }
                  src={
                    makePublic[isPublic] === 'Make Private'
                      ? publicViewImage.avatar
                      : privateViewImage.avatar
                  }
                  sx={{
                    width: '15px',
                    height: '15px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    right: '29px'
                  }}
                />
                <Box
                  component="img"
                  alt={telegramView.name}
                  src={telegramView.avatar}
                  onClick={goTelegram}
                  sx={{
                    width: '18px',
                    height: '18px',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '0',
                    bottom: '0',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    right: '0'
                  }}
                />
              </>
            )}
          </Box>
          <RowBox className={classes.visionaryBoxStyle}>
            {(lStatus === 0 || !account) && (
              <Typography
                component="span"
                variant="h4"
                sx={{
                  textAlign: 'center',
                  padding: '5px 10px',
                  color: theme.colors.inactiveTitle.main,
                  background: theme.colors.primaryDark.main,
                  borderRadius: '20px',
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer'
                }}
                onClick={onGotoSmartArmyPage}
              >
                Please exchange license
              </Typography>
            )}
            {lStatus === 1 && account && (
              <Typography
                component="span"
                variant="h5"
                sx={{
                  textAlign: 'center',
                  padding: '5px 10px',
                  color: `${
                    isLoading
                      ? theme.colors.inactiveTitle.main
                      : theme.colors.activeTitle.main
                  }`,
                  background: `${
                    isLoading
                      ? theme.colors.primaryDark.main
                      : theme.colors.primary.main
                  }`,
                  borderRadius: '20px',
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={onHandleActivate}
              >
                Activate License {isLoading && <LoadingBar />}
              </Typography>
            )}
            {lStatus >= 2 && account && (
              <>
                <Typography
                  component="span"
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    padding: '7px 7px',
                    color: theme.colors.inactiveTitle.main,
                    background: theme.colors.primaryDark.main,
                    borderRadius: '20px',
                    height: '100%',
                    width: '60%'
                  }}
                >
                  {userInfo.licenseName} License
                </Typography>
                <Typography
                  component="span"
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    padding: '5px 15px',
                    color: '#fff',
                    width: '40%'
                  }}
                >
                  {remain}
                </Typography>
              </>
            )}
          </RowBox>
          {lStatus > 1 && account && (
            <ColumBox>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '32px',
                  marginTop: '7px',
                  zIndex: '1'
                }}
              >
                <CardActions
                  sx={{
                    background: theme.colors.gradients.primary,
                    border: `1.5px solid ${theme.colors.borderColor.main}`,
                    boxSizing: 'border-box',
                    borderRadius: '10px',
                    height: '100%',
                    padding: '0 66px',
                    position: 'relative',
                    cursor: 'pointer',
                    justifyContent: 'center'
                  }}
                  onClick={handleExpandClick}
                >
                  <Typography variant="h3" component="span" color="#EDEDED">
                    Privilege
                  </Typography>
                  <ExpandMore expand={expanded}>
                    <ArrowDropDownIcon sx={{ color: '#E0A501' }} />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent
                    sx={{
                      padding: '42px 10px 12px 10px !important',
                      width: '100%',
                      background: theme.colors.gradients.primary,
                      border: `1.5px solid ${theme.colors.borderColor.main}`,
                      boxSizing: 'border-box',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      marginTop: '-32px',
                      textAlign: 'left'
                    }}
                  >
                    {Number(userInfo.licenseLevel) - 1 >= 0 && account && (
                      <>
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Teamwork ladder lv.
                          {
                            privilege[Number(userInfo.licenseLevel) - 1][
                              'ladder'
                            ]
                          }
                        </Typography>
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Entitled to be an SMT intermediary
                        </Typography>
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Farming rewards:
                        </Typography>
                        {
                          privilege[Number(userInfo.licenseLevel) - 1][
                            'rewards'
                          ]
                        }
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Access to Smart Academy, Smart Living, Smart
                          Utilities, Smart Wealth (
                          {
                            privilege[Number(userInfo.licenseLevel) - 1][
                              'title'
                            ]
                          }
                          )
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Collapse>
              </Box>
            </ColumBox>
          )}
        </Box>
        {/* MIDDLE SECTION */}
        <Box marginTop="30px">
          {middleButtons.map((con, idx) => (
            <CardActions
              key={idx}
              sx={{
                background: theme.colors.gradients.primary,
                border: `1.5px solid ${theme.colors.borderColor.main}`,
                boxSizing: 'border-box',
                borderRadius: '10px',
                height: '40px',
                width: '100%',
                padding: '0 50px',
                position: 'relative',
                cursor: 'pointer',
                justifyContent: 'space-between',
                marginTop: '10px'
              }}
            >
              <Typography
                component="span"
                variant="h3"
                sx={{
                  textAlign: 'center',
                  height: '25px'
                }}
              >
                {con}
              </Typography>
              <ArrowDropDownIcon sx={{ color: theme.colors.primary.main }} />
            </CardActions>
          ))}
        </Box>
      </Box>
      {/* BOTTOM SETION */}
      <RowBox marginTop="50px" justifyContent="center">
        <RowBox width="50%">
          <AntSwitch
            inputProps={{ 'aria-label': 'setted' }}
            onChange={(e) => changeSwitch(e)}
          />
          <Typography variant="h4" color={theme.colors.primary.main}>
            {makePublic[isPublic]}
          </Typography>
        </RowBox>
        {makePublic[isPublic] === 'Make Private' && (
          <RowBox width="40%" marginLeft="30px">
            <Typography variant="h4" color={theme.colors.primary.main}>
              Share Profile
            </Typography>
            <Box
              component="img"
              src={shareIcon.path}
              alt={shareIcon.name}
              onClick={props.onHandleShareClick}
              sx={{
                cursor: 'pointer'
              }}
            />
          </RowBox>
        )}
      </RowBox>
    </Box>
  );
};

export default PublicProfileContent;
