import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import useSmartArmy from 'src/hooks/useSmartArmy';
import { convertMiliseconds } from 'src/utils/licenseInfo';

const useTimeCount = (limit: string) => {
  const { account, chainId } = useWeb3React();
  const [pastTime, setPastTime] = useState<string>('');
  const { fetchLicense } = useSmartArmy();

  useEffect(() => {
    let interval;
    async function init() {
      await fetchLicense(account).then((res) => {
        if (res) {
          if (res.status < 2) return;
          let dif =
            Date.now() -
            parseInt((res.activeAt - 3600 * 48).toString() + '000');
          interval = setInterval(() => {
            // 1s = 1000 milis
            dif += 1000;
            if (limit) setPastTime(convertMiliseconds(dif, limit));
            else setPastTime(convertMiliseconds(dif));
          }, 1000);
        }
      });
    }
    if (account) init();

    return () => {
      clearInterval(interval);
    };
  }, [account, chainId]);

  return { pastTime };
};

export const useTimeDiscount = (userAccount, limit: string) => {
  const { chainId } = useWeb3React();
  const { fetchLicense } = useSmartArmy();
  const [remain, setRemain] = useState<string>('');

  useEffect(() => {
    let interval;
    async function init() {
      await fetchLicense(userAccount).then((res) => {
        if (res) {
          if (res.status < 2) return;
          let dif = parseInt(res.expireAt.toString() + '000') - Date.now();
          interval = setInterval(() => {
            // 1s = 1000 milis
            dif -= 1000;
            if (limit) setRemain(convertMiliseconds(dif, limit));
            else setRemain(convertMiliseconds(dif));
          }, 1000);
        }
      });
    }
    if (userAccount) init();

    return () => {
      clearInterval(interval);
      setRemain('');
    };
  }, [userAccount, chainId]);

  return { remain };
};

export default useTimeCount;
