import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

// @mui
import {
  Box,
  Typography,
  CardContent,
  CardActions,
  Collapse,
  Theme,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// components
import RowBox from 'src/components/Box/RowBox';
import ColumnBox from 'src/components/Box/ColumnBox';
import CustomButtom from 'src/components/Button';
import WalletDialog from '../../wallet-modal';
import LoadingBar from 'src/components/Loader';

// models
import {
  userAvatar,
  profileMark,
  privateViewImage,
  telegramView,
  nonProfileImage
} from 'src/models/ImageUrl';
import { ExpandMore } from 'src/models/layout/StyledData';
import { privilege } from 'src/models/layout/SampleData';
import { tooltipImages } from 'src/models/main/achievement/SampleData';

// hooks
import { useWeb3React } from '@web3-react/core';
import useSmartArmy from 'src/hooks/useSmartArmy';
import useSmartAchievement from 'src/hooks/useSmartAchievement';
import { useTimeDiscount } from 'src/hooks/useTimeCount';

// utils
import { shorter } from 'src/utils';
import { UserInfoProps } from 'src/utils/interfaces';
import getUserInfo from 'src/utils/getUserInfo';

const useStyles = makeStyles({
  visionaryBoxStyle: (props: Theme) => ({
    height: '25px',
    marginTop: '13px',
    background: props.colors.primaryDark.main,
    borderRadius: '20px'
  }),
  customOutBoxStyle: (props: Theme) => ({
    padding: '60px 20px 50px 20px',
    width: '280px !important',
    height: '100vh',
    background: props.colors.gradients.black4,
    borderRadius: '20px 0px 0px 20px'
  })
});

let entered: boolean = false;
const initialInfo = {
  userName: '',
  telegram: '',
  userAvatar: '',
  licenseName: '',
  licenseLevel: '0',
  nobilityTitle: ''
};

const MobileRightSidebarMenu = () => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);
  const navigate = useNavigate();
  const { account, chainId } = useWeb3React();
  const { initActivate, isLoading, setIsLoading } = useSmartArmy();
  const { getNobilityTypeOf } = useSmartAchievement();
  const { remain } = useTimeDiscount(account, 'm');

  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded((expanded) => !expanded);
  };

  const [walletOpen, setWalletOpen] = useState(false);
  const handleWalletClickOpen = () => {
    if (!account) setWalletOpen(true);
  };
  const handleWalletClose = () => {
    setWalletOpen(false);
  };

  const onGotoSmartArmyPage = () => {
    navigate('/main/smart');
  };

  const [lStatus, setLstatus] = useState<number>(0);
  const [userInfo, setUserInfo] = useState<UserInfoProps>(initialInfo);

  useEffect(() => {
    async function getStatusOfLicense() {
      await getUserInfo(account, chainId).then((res) => {
        if (res) {
          const {
            userAvatar,
            userName,
            telegram,
            licenseName,
            licenseLevel,
            nobilityTitle,
            licenseStatus
          } = res;
          setUserInfo({
            userAvatar,
            userName,
            telegram,
            licenseName,
            licenseLevel,
            nobilityTitle
          });
          setLstatus(licenseStatus);
        }
      });
    }
    if (account) getStatusOfLicense();

    return () => {
      setUserInfo(initialInfo);
      setLstatus(0);
    };
  }, [lStatus, account, chainId]);

  const onHandleActivate = async () => {
    if (!entered && !isLoading) {
      try {
        entered = true;
        setIsLoading(true);
        await initActivate();
        setLstatus(2);
      } catch (err) {
        console.error(err);
        toast.error(err.message);
        setLstatus((prev) => prev);
      } finally {
        entered = false;
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <ColumnBox className={classes.customOutBoxStyle}>
        <ColumnBox>
          <CustomButtom
            width="132px"
            height="32px"
            borderRadius="20px"
            fontSize="14px"
            padding="7px"
            onHandleClick={handleWalletClickOpen}
          >
            {account ? shorter(account) : 'Connect Wallet'}
          </CustomButtom>

          <Box
            width="150px"
            sx={{ position: 'relative', margin: '30px auto 0 auto' }}
          >
            {lStatus === 0 || !account ? (
              <Box
                component="img"
                alt={userAvatar.name}
                src={nonProfileImage.avatar}
                sx={{
                  width: '150px',
                  // height: '150px',
                  margin: 'auto'
                }}
              />
            ) : (
              <>
                <Box
                  component="img"
                  alt={userAvatar.name}
                  src={userInfo.userAvatar}
                  sx={{
                    width: '150px',
                    height: '150px',
                    margin: 'auto',
                    borderRadius: '50%'
                  }}
                />
                {userInfo.nobilityTitle !== '' && (
                  <Box
                    component="img"
                    alt={profileMark.name}
                    src={tooltipImages[userInfo.nobilityTitle].path}
                    sx={{
                      width: '72px',
                      cursor: 'pointer',
                      position: 'absolute',
                      bottom: '0',
                      right: '-20px'
                    }}
                  />
                )}
              </>
            )}
          </Box>
          <RowBox marginTop="16px" justifyContent="center">
            <Typography variant="h2">
              {lStatus === 0 || !account ? 'User Name' : userInfo.userName}
            </Typography>
            {lStatus !== 0 && (
              <>
                <Box
                  component="img"
                  alt={privateViewImage.name}
                  src={privateViewImage.avatar}
                  sx={{
                    width: '15px',
                    height: '15px',
                    cursor: 'pointer',
                    marginLeft: '20px'
                  }}
                />
                <Box
                  component="img"
                  alt={telegramView.name}
                  src={telegramView.avatar}
                  sx={{
                    width: '18px',
                    height: '18px',
                    cursor: 'pointer',
                    marginLeft: '10px'
                  }}
                />
              </>
            )}
          </RowBox>
          <RowBox className={classes.visionaryBoxStyle}>
            {(lStatus === 0 || !account) && (
              <Typography
                component="span"
                variant="h5"
                sx={{
                  textAlign: 'center',
                  padding: '5px 10px',
                  color: '#212121',
                  background: '#5A5A5A',
                  borderRadius: '20px',
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer'
                }}
                onClick={onGotoSmartArmyPage}
              >
                Please exchange license
              </Typography>
            )}
            {lStatus === 1 && account && (
              <Typography
                component="span"
                variant="h5"
                sx={{
                  textAlign: 'center',
                  padding: '5px 10px',
                  color: '#212121',
                  background: '#E0A501',
                  borderRadius: '20px',
                  height: '100%',
                  width: '100%',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                onClick={onHandleActivate}
              >
                Activate License {isLoading && <LoadingBar />}
              </Typography>
            )}
            {lStatus >= 2 && account && (
              <>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    padding: '5px 10px',
                    color: '#212121',
                    background: '#E0A501',
                    borderRadius: '20px',
                    height: '100%',
                    width: '60%'
                  }}
                >
                  {userInfo.licenseName} License
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    padding: '5px 15px',
                    color: '#fff',
                    width: '40%'
                  }}
                >
                  {remain}
                </Typography>
              </>
            )}
          </RowBox>

          {lStatus > 1 && account && (
            <ColumnBox height="25px">
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '32px',
                  marginTop: '7px',
                  zIndex: '1'
                }}
              >
                <CardActions
                  disableSpacing
                  sx={{
                    background: theme.colors.gradients.primary,
                    border: `1.5px solid ${theme.colors.borderColor.main}`,
                    boxSizing: 'border-box',
                    borderRadius: '10px',
                    height: '100%',
                    padding: '0 66px',
                    position: 'relative',
                    cursor: 'pointer'
                  }}
                  onClick={handleExpandClick}
                >
                  <Typography
                    component="span"
                    variant="h3"
                    sx={{
                      textAlign: 'center',
                      width: '110px',
                      height: '25px',
                      color: '#EDEDED'
                    }}
                  >
                    Privilege
                  </Typography>
                  <ExpandMore
                    expand={expanded}
                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ArrowDropDownIcon sx={{ color: '#E0A501' }} />
                  </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent
                    sx={{
                      padding: '42px 10px 12px 10px !important',
                      width: '100%',
                      background: theme.colors.gradients.primary,
                      border: `1.5px solid ${theme.colors.borderColor.main}`,
                      boxSizing: 'border-box',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      marginTop: '-32px'
                    }}
                  >
                    {Number(userInfo.licenseLevel) - 1 >= 0 && account && (
                      <>
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Teamwork ladder lv.
                          {
                            privilege[Number(userInfo.licenseLevel) - 1][
                              'ladder'
                            ]
                          }
                        </Typography>
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Entitled to be an SMT intermediary
                        </Typography>
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Farming rewards:
                        </Typography>
                        {
                          privilege[Number(userInfo.licenseLevel) - 1][
                            'rewards'
                          ]
                        }
                        <Typography variant="h5" color="#E0A501">
                          &#8226; Access to Smart Academy, Smart Living, Smart
                          Utilities, Smart Wealth (
                          {
                            privilege[Number(userInfo.licenseLevel) - 1][
                              'title'
                            ]
                          }
                          )
                        </Typography>
                      </>
                    )}
                  </CardContent>
                </Collapse>
              </Box>
            </ColumnBox>
          )}
        </ColumnBox>

        <Box display="flex" flexDirection="column" alignItems="center">
          <CustomButtom
            width="154px"
            height="34px"
            borderRadius="20px"
            fontSize="14px"
          >
            Light Mode
          </CustomButtom>

          <CustomButtom
            width="154px"
            height="34px"
            background="#5A5A5A"
            color="#EDEDED"
            borderRadius="20px"
            fontSize="14px"
            marginTop="10px"
          >
            Settings
          </CustomButtom>
        </Box>
      </ColumnBox>

      <WalletDialog open={walletOpen} onClose={handleWalletClose} />
    </>
  );
};

export default MobileRightSidebarMenu;
