import { ReactNode } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

interface CustomButtonProps {
  children?: ReactNode;
  title?: string;
  boxShadow?: string;
  border?: string;
  padding?: string;
  hoverBackground?: string;
  hoverColor?: string;
  height?: string;
  width?: string;
  background?: string;
  color?: string;
  borderRadius: string;
  marginTop?: string;
  fontSize: string;
  fontWeight?: string;
  buttonType?: any;
  onHandleClick?: (
    e: React.MouseEvent<HTMLButtonElement>,
    value?: string,
    value1?: string
  ) => void;
}

const CustomButton = (props: CustomButtonProps) => {
  const StyledButton = styled(Button)(({ theme }) => ({
    ...props,
    padding: props.padding || '7px 15px',
    lineHeight: '100%',
    background: props.background || theme.colors.primary.main,
    color: props.color || theme.colors.activeTitle.main,
    '&:hover': {
      background: props.hoverBackground || theme.colors.primaryDark.main,
      color: props.hoverColor || theme.colors.inactiveTitle.main
    }
  }));

  return (
    <StyledButton
      onClick={props.onHandleClick}
      type={props.buttonType || 'submit'}
    >
      {props.children || props.title}
    </StyledButton>
  );
};

export default CustomButton;
