// hooks
import useMulticallContract from 'src/hooks/useMulticallContract';
import { ContractCallContext } from 'ethereum-multicall';
import { ethers } from 'ethers';

// utils
import { getContractInfo, numberWithCommas } from 'src/utils';
import { licenseLevelToName } from 'src/utils/licenseInfo';

const getUserInfo = async (account: string, chainId: number) => {
  const { getMulticallResults } = useMulticallContract();
  let userName = '';
  let telegram = '';
  let userAvatar = '';
  let licenseName = '';
  let licenseLevel = '0';
  let nobilityTitle = '';
  let licenseStatus = 0;

  const callData: ContractCallContext[] = [
    {
      reference: 'SmartArmy',
      contractAddress: getContractInfo('SmartArmy', chainId).address,
      abi: getContractInfo('SmartArmy', chainId).abi,
      calls: [
        {
          reference: 'licenseInfo',
          methodName: 'licenseOf',
          methodParameters: [account]
        },
        {
          reference: 'userInfo',
          methodName: 'userInfo',
          methodParameters: [account]
        }
      ]
    },
    {
      reference: 'SmartNobilityAchievement',
      contractAddress: getContractInfo('SmartNobilityAchievement', chainId)
        .address,
      abi: getContractInfo('SmartNobilityAchievement', chainId).abi,
      calls: [
        {
          reference: 'nobilityTitleOf',
          methodName: 'nobilityTitleOf',
          methodParameters: [account]
        }
      ]
    }
  ];
  await getMulticallResults(callData)
    .then((res) => {
      console.log(res);
      const { SmartArmy, SmartNobilityAchievement } = res.results;
      licenseLevel = ethers.BigNumber.from(
        SmartArmy.callsReturnContext[0].returnValues[1].hex
      ).toString();
      licenseName = licenseLevelToName(licenseLevel);
      nobilityTitle =
        SmartNobilityAchievement.callsReturnContext[0].returnValues[0];
      userName = SmartArmy.callsReturnContext[1].returnValues[1];
      telegram = SmartArmy.callsReturnContext[1].returnValues[2];
      userAvatar = SmartArmy.callsReturnContext[0].returnValues[6];
      licenseStatus = SmartArmy.callsReturnContext[0].returnValues[7];
    })
    .catch((err) => {
      console.error(err);
    });

  return {
    userName,
    userAvatar,
    telegram,
    licenseName,
    licenseLevel,
    nobilityTitle,
    licenseStatus
  };
};

export default getUserInfo;
