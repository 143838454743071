import { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// mui
import {
  Box,
  Drawer,
  Hidden,
  Typography,
  CardContent,
  CardActions,
  Collapse,
  Theme,
  useTheme
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// third party
import { Scrollbars } from 'react-custom-scrollbars-2';
import { toast } from 'react-hot-toast';

// utils
import { UserInfoProps } from 'src/utils/interfaces';
import getUserInfo from 'src/utils/getUserInfo';

// hooks
import { useWeb3React } from '@web3-react/core';
import useSmartArmy from 'src/hooks/useSmartArmy';
import { useTimeDiscount } from 'src/hooks/useTimeCount';
import { SidebarContext } from 'src/contexts/SidebarContext';

// components
import Logo from 'src/components/Logo';
import SidebarMenu from './SidebarMenu';
import WealthSidebarMenu from './WealthSidebarMenu';
import MobileSideBarMenu from './MobileSidebarMenu';
import MobileRightSideBarMenu from './MobileRightSidebarMenu';
import ColumnBox from 'src/components/Box/ColumnBox';
import LoadingBar from 'src/components/Loader';
import RowBox from 'src/components/Box/RowBox';

// models
import { ExpandMore, LightModeButton } from 'src/models/StyledData';
import {
  userAvatar,
  profileMark,
  publicViewImage,
  telegramView,
  nonProfileImage,
  settingImage
} from 'src/models/ImageUrl';
import { SidebarWrapper, TopSection, DownSection } from 'src/models/StyledData';
import { privilege } from 'src/models/layout/SampleData';
import { tooltipImages } from 'src/models/main/achievement/SampleData';

let entered: boolean = false;
const initialInfo = {
  userName: '',
  telegram: '',
  userAvatar: '',
  licenseName: '',
  licenseLevel: '0',
  nobilityTitle: ''
};

const Sidebar = () => {
  const theme: Theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { account, chainId } = useWeb3React();
  const { initActivate, isLoading, setIsLoading } = useSmartArmy();
  const { remain } = useTimeDiscount(account, 'm');

  const [lStatus, setLstatus] = useState<number>(0);
  const [userInfo, setUserInfo] = useState<UserInfoProps>(initialInfo);

  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpandClick = () => {
    setExpanded((expanded) => !expanded);
  };
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const { rightSidebarToggle, toggleRightSidebar } = useContext(SidebarContext);
  const closeRightSidebar = () => toggleRightSidebar();

  const onGotoSmartArmyPage = () => {
    navigate('/main/smart');
  };
  const goTelegram = () => {
    window.open('https://t.me/' + userInfo.telegram);
  };
  const handleClickOpen = () => {
    alert('LightMode');
  };

  useEffect(() => {
    async function getStatusOfLicense() {
      await getUserInfo(account, chainId).then((res) => {
        if (res) {
          const {
            userAvatar,
            userName,
            telegram,
            licenseName,
            licenseLevel,
            nobilityTitle,
            licenseStatus
          } = res;
          setUserInfo({
            userAvatar,
            userName,
            telegram,
            licenseName,
            licenseLevel,
            nobilityTitle
          });
          setLstatus(licenseStatus);
        }
      });
    }
    if (account) getStatusOfLicense();

    return () => {
      setUserInfo(initialInfo);
      setLstatus(0);
    };
  }, [lStatus, account, chainId]);

  const onHandleActivate = async () => {
    if (!entered && !isLoading) {
      try {
        entered = true;
        setIsLoading(true);
        await initActivate();
        setLstatus(2);
      } catch (err) {
        console.error(err);
        toast.error(err.message);
        setLstatus((prev) => prev);
      } finally {
        entered = false;
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Logo />
              <Box marginTop="20px" width="260px" padding="0 10px">
                <Box
                  sx={{
                    position: 'relative',
                    textAlign: 'center',
                    width: '100%'
                  }}
                >
                  {lStatus === 0 || !account ? (
                    <Box
                      component="img"
                      alt={nonProfileImage.name}
                      src={nonProfileImage.avatar}
                      sx={{
                        width: '150px'
                      }}
                    />
                  ) : (
                    <>
                      <Box
                        component="img"
                        alt={userAvatar.name}
                        src={userInfo.userAvatar}
                        sx={{
                          width: '150px',
                          height: '150px',
                          cursor: 'pointer',
                          margin: 'auto',
                          borderRadius: '50%'
                        }}
                      />
                      {userInfo.nobilityTitle !== '' && (
                        <Box
                          component="img"
                          alt={profileMark.name}
                          src={tooltipImages[userInfo.nobilityTitle].path}
                          sx={{
                            width: '72px',
                            cursor: 'pointer',
                            position: 'absolute',
                            bottom: '0',
                            right: '-20px'
                          }}
                        />
                      )}
                    </>
                  )}
                </Box>
                <Box sx={{ position: 'relative', marginTop: '16px' }}>
                  {lStatus !== 0 && (
                    <>
                      <Box
                        component="img"
                        alt={publicViewImage.name}
                        src={publicViewImage.avatar}
                        sx={{
                          width: '15px',
                          height: '15px',
                          position: 'absolute',
                          cursor: 'pointer',
                          top: '0',
                          bottom: '0',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          right: '29px'
                        }}
                      />
                      <Box
                        component="img"
                        alt={telegramView.name}
                        src={telegramView.avatar}
                        onClick={goTelegram}
                        sx={{
                          width: '18px',
                          height: '18px',
                          position: 'absolute',
                          top: '0',
                          bottom: '0',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          right: '0',
                          cursor: 'pointer'
                        }}
                      />
                    </>
                  )}
                  <Typography variant="h2" textAlign="center">
                    {lStatus === 0 || !account
                      ? 'User Name'
                      : userInfo.userName}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '25px',
                    marginTop: '13px',
                    background: '#5A5A5A',
                    borderRadius: '20px',
                    display: 'flex'
                  }}
                >
                  {(lStatus === 0 || !account) && (
                    <Typography
                      component="span"
                      variant="h5"
                      sx={{
                        textAlign: 'center',
                        padding: '5px 10px',
                        color: '#212121',
                        background: '#5A5A5A',
                        borderRadius: '20px',
                        height: '100%',
                        width: '100%',
                        cursor: 'pointer'
                      }}
                      onClick={onGotoSmartArmyPage}
                    >
                      Please exchange license
                    </Typography>
                  )}
                  {lStatus === 1 && account && (
                    <Typography
                      component="span"
                      variant="h5"
                      sx={{
                        textAlign: 'center',
                        padding: '5px 10px',
                        color: '#212121',
                        background: `${isLoading ? '#936900' : '#E0A501'}`,
                        borderRadius: '20px',
                        height: '100%',
                        width: '100%',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={onHandleActivate}
                    >
                      Activate License {isLoading && <LoadingBar />}
                    </Typography>
                  )}
                  {lStatus >= 2 && account && (
                    <>
                      <Typography
                        component="span"
                        sx={{
                          fontSize: '11px',
                          textAlign: 'center',
                          padding: '4px 10px',
                          color: '#212121',
                          background: '#E0A501',
                          borderRadius: '20px',
                          height: '100%',
                          width: '60%'
                        }}
                      >
                        {userInfo.licenseName} License
                      </Typography>
                      <Typography
                        component="span"
                        variant="h5"
                        sx={{
                          textAlign: 'center',
                          padding: '7px 15px',
                          color: '#fff',
                          width: '40%'
                        }}
                      >
                        {remain}
                      </Typography>
                    </>
                  )}
                </Box>
                {lStatus > 1 && account && (
                  <ColumnBox height="25px">
                    <Box
                      sx={{
                        position: 'relative',
                        width: '100%',
                        height: '32px',
                        marginTop: '7px',
                        zIndex: '1'
                      }}
                    >
                      <CardActions
                        disableSpacing
                        sx={{
                          background: theme.colors.gradients.primary,
                          border: `1.5px solid ${theme.colors.borderColor.main}`,
                          boxSizing: 'border-box',
                          borderRadius: '10px',
                          height: '100%',
                          padding: '0 66px',
                          position: 'relative',
                          cursor: 'pointer'
                        }}
                        onClick={handleExpandClick}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            textAlign: 'center',
                            width: '110px',
                            height: '25px',
                            color: '#EDEDED',
                            letterSpacing: '1px'
                          }}
                        >
                          Privilege
                        </Typography>
                        <ExpandMore
                          expand={expanded}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <ArrowDropDownIcon sx={{ color: '#E0A501' }} />
                        </ExpandMore>
                      </CardActions>
                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent
                          sx={{
                            padding: '42px 10px 12px 10px !important',
                            width: '100%',
                            background: theme.colors.gradients.primary,
                            border: `1.5px solid ${theme.colors.borderColor.main}`,
                            boxSizing: 'border-box',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            marginTop: '-32px'
                          }}
                        >
                          {Number(userInfo.licenseLevel) - 1 >= 0 &&
                            account && (
                              <>
                                <Typography variant="h5" color="#E0A501">
                                  &#8226; Teamwork ladder lv.
                                  {
                                    privilege[
                                      Number(userInfo.licenseLevel) - 1
                                    ]['ladder']
                                  }
                                </Typography>
                                <Typography variant="h5" color="#E0A501">
                                  &#8226; Entitled to be an SMT intermediary
                                </Typography>
                                <Typography variant="h5" color="#E0A501">
                                  &#8226; Farming rewards:
                                </Typography>
                                {
                                  privilege[Number(userInfo.licenseLevel) - 1][
                                    'rewards'
                                  ]
                                }
                                <Typography variant="h5" color="#E0A501">
                                  &#8226; Access to Smart Academy, Smart Living,
                                  Smart Utilities, Smart Wealth (
                                  {
                                    privilege[
                                      Number(userInfo.licenseLevel) - 1
                                    ]['title']
                                  }
                                  )
                                </Typography>
                              </>
                            )}
                        </CardContent>
                      </Collapse>
                    </Box>
                  </ColumnBox>
                )}
              </Box>
            </TopSection>

            <DownSection>
              {location.pathname.includes('/wealth') ? (
                <>
                  <WealthSidebarMenu />
                  <RowBox
                    padding="0 30px"
                    margin="265px 0"
                    justifyContent="space-evenly"
                  >
                    <Box
                      component="img"
                      alt={settingImage.name}
                      src={settingImage.avatar}
                      sx={{
                        width: '28.88px',
                        height: '29.67px',
                        cursor: 'pointer'
                      }}
                    />
                    <LightModeButton
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      Light Mode
                    </LightModeButton>
                  </RowBox>
                </>
              ) : (
                <>
                  <SidebarMenu />
                  <RowBox
                    padding="0 30px"
                    margin="25px 0"
                    justifyContent="space-evenly"
                  >
                    <Box
                      component="img"
                      alt={settingImage.name}
                      src={settingImage.avatar}
                      sx={{
                        width: '28px',
                        height: '29px',
                        cursor: 'pointer'
                      }}
                    />
                    <LightModeButton
                      variant="contained"
                      onClick={handleClickOpen}
                    >
                      Light Mode
                    </LightModeButton>
                  </RowBox>
                </>
              )}
            </DownSection>
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>

      {/* LEFT SIDE BAR IN THE MOBILE */}
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper sx={{ paddingTop: '200px' }}>
            <MobileSideBarMenu />
          </SidebarWrapper>
        </Drawer>
      </Hidden>

      {/* RIGHT SIDE BAR IN THE MOBILE */}
      <Hidden lgUp>
        <Drawer
          anchor="right"
          open={rightSidebarToggle}
          onClose={closeRightSidebar}
          variant="temporary"
          elevation={9}
        >
          <MobileRightSideBarMenu />
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
