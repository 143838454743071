import { Box, Typography } from '@mui/material';

interface ParentProps {
  title: string;
  height?: string;
  width?: string;
  color?: string;
  background?: string;
  fontSize?: string;
  fontWeight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginRight?: string;
  marginLeft?: string;
  boxShadow?: string;
  padding?: string;
  borderRadius?: string;
  border?: string;
  onHandleClick?: (e: React.MouseEvent, value?: number) => void;
}

const BadgeTitle = (props: ParentProps) => {
  const { title, onHandleClick, color, ...styleProps } = props;

  return (
    <Typography
      variant="h4"
      component="span"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      padding="3px 5px"
      borderRadius="20px"
      sx={{ ...styleProps, color: `${color} !important`, cursor: 'pointer' }}
      onClick={onHandleClick}
    >
      {title}
    </Typography>
  );
};

export default BadgeTitle;
