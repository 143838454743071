import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

// @mui
import { Box, Typography, Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

// components
import CustomTitle from 'src/components/Title/BadgeTitle';
import RowBox from 'src/components/Box/RowBox';

// hooks
import { useWeb3React } from '@web3-react/core';

interface ParentProps {
  onHandleCustoClose: (e: React.MouseEvent<SVGElement>) => void;
}

const useStyles = makeStyles({
  outBoxStyle: (props: Theme) => ({
    padding: '50px 33px 70px 33px !important',
    position: 'relative',
    background: 'linear-gradient(180deg, #212121 0%, #000000 100%)',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '100%',
    textAlign: 'center'
  })
});

const qrCodeImage = {
  name: 'qrCodeImage',
  path: '/static/img/header/qrcode.svg',
  desc: 'qrCodeImage'
};
const copyIcon = {
  name: 'copyIcon',
  path: '/static/img/header/copy.svg',
  desc: 'copyIcon'
};

const ShareProfile = (props: ParentProps) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const { account } = useWeb3React();
  const [referralLink, setReferralLink] = useState('');

  useEffect(() => {
    if (account) {
      setReferralLink(`${window.location.origin}/p-id=${account}`);
    }
  }, [account]);

  const handleCopy = (): void => {
    toast.success('copied');
    navigator.clipboard.writeText(referralLink);
  };

  return (
    <>
      <Box className={classes.outBoxStyle}>
        <CloseIcon
          onClick={props.onHandleCustoClose}
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: '15px',
            right: '15px',
            color: theme.colors.secondary.main
          }}
        />
        <Box>
          <Typography variant="h2">Share your public profile</Typography>
          <Box marginTop="70px">
            <Box
              component="img"
              src={qrCodeImage.path}
              alt={qrCodeImage.name}
              margin="0 auto"
            />
            <Typography variant="h3" marginTop="30px">
              Share your public profile
            </Typography>
            <Typography variant="h4" marginTop="5px">
              or
            </Typography>
            <Typography variant="h3" margin="5px 0 20px 0">
              Use link instead
            </Typography>
            <RowBox>
              <CustomTitle
                title={
                  account ? `Smart-Ecosystem/p-id=${account.slice(0, 8)}` : ''
                }
                width="90%"
                height="36px"
                color={theme.colors.secondary.main}
                background={theme.colors.gradients.primary}
                fontSize="14px"
                borderRadius="10px"
                padding="9px 25px"
                border={`1.5px solid ${theme.colors.borderColor.main}`}
              />
              <Box
                component="img"
                src={copyIcon.path}
                alt={copyIcon.name}
                sx={{ cursor: 'pointer' }}
                onClick={handleCopy}
              />
            </RowBox>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShareProfile;
